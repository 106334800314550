import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Divider, List, ListItem, ListItemText, Button, Typography, AppBar, Toolbar, Modal, Box, TextField, SpeedDial, SpeedDialAction, Snackbar, Alert, Badge, ListItemAvatar, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import IconButton from '@mui/material/IconButton';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LuggageIcon from '@mui/icons-material/Luggage';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';

// Import images
import logo from '../assets/images/logo.png';
import smallAvailableImage from '../assets/images/sa.png';
import smallOccupiedImage from '../assets/images/so.png';
import bigAvailableImage from '../assets/images/ba.png';
import bigOccupiedImage from '../assets/images/bo.png';
import { AuthContext } from '../context/authContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function LockerControlSystemPage() {
  const [lockers, setLockers] = useState([]);
  const [open, setOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [showCamera, setShowCamera] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [lockerSize, setLockerSize] = useState('s');
  const [smallAvailable, setSmallAvailable] = useState(0);
  const [smallOccupied, setSmallOccupied] = useState(0);
  const [bigAvailable, setBigAvailable] = useState(0);
  const [bigOccupied, setBigOccupied] = useState(0);
  const [totalOccupied, setTotalOccupied] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [totalLockers, setTotalLockers] = useState(0);
  const [badgeFilter, setBadgeFilter] = useState(''); // New state for badge filter
  const webcamRef = useRef(null);
  const intervalRef = useRef(null);
  const { authState } = useContext(AuthContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState.user) {
      navigate('/'); // Redirect to login if user is not authenticated
      return;
    }

    const fetchLockers = async () => {
      console.log(authState.token);
      try {
        const response = await axios.get('https://serverlocker.winglet.app/lockers', {
          headers: {
            Authorization: `Bearer ${authState.token}`
          }
        });
        console.log(response)
        if (Array.isArray(response.data)) {
          setLockers(response.data);
        } else {
          console.error("Fetched data is not an array:", response.data);
          console.log(response.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            navigate('/'); // Redirect to login on 401 Unauthorized
          } else if (error.response.status === 403) {
            console.error("Forbidden access - 403");
          } else {
            console.error("Error fetching lockers:", error.response.status, error.response.data);
          }
        } else {
          console.error("Error fetching lockers:", error.message);
        }
      }
    };

    fetchLockers();
  }, [authState, navigate]);

  useEffect(() => {
    const calculateCounts = () => {
      const smallAvailableCount = lockers.filter(locker => !locker.is_occupied && locker.size === 's').length;
      const smallOccupiedCount = lockers.filter(locker => locker.is_occupied && locker.size === 's').length;
      const bigAvailableCount = lockers.filter(locker => !locker.is_occupied && locker.size === 'b').length;
      const bigOccupiedCount = lockers.filter(locker => locker.is_occupied && locker.size === 'b').length;
      const totalLockers = smallAvailableCount + bigAvailableCount + smallOccupiedCount + bigOccupiedCount;
      const percentOccupied = ((smallAvailableCount + bigAvailableCount) / totalLockers) * 100;
      const percentAvailable = ((smallAvailableCount + bigAvailableCount) / totalLockers) / 100;
      const formattedPercentOccupied = percentOccupied.toFixed(2); // Format to 2 decimal places
      const formattedPercentAvailable = percentAvailable.toFixed(2); // Format to 2 decimal places

      setSmallAvailable(smallAvailableCount);
      setSmallOccupied(smallOccupiedCount);
      setBigAvailable(bigAvailableCount);
      setBigOccupied(bigOccupiedCount);
      setTotalOccupied(formattedPercentOccupied);
      setTotalAvailable(formattedPercentAvailable);
      setTotalLockers(totalLockers);
    };

    calculateCounts();
  }, [lockers]);

  useEffect(() => {
    if (open && showCamera) {
      intervalRef.current = setInterval(() => {
        captureAndRecognize();
      }, 1000); // Capture every second
    } else {
      clearInterval(intervalRef.current);
      if (webcamRef.current && webcamRef.current.video && webcamRef.current.video.srcObject) {
        webcamRef.current.video.srcObject.getTracks().forEach(track => track.stop());
      }
    }
    return () => clearInterval(intervalRef.current);
  }, [open, showCamera]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    // Implement logout functionality
    navigate('/');
  };

  const assignRandomLocker = async (size) => {
    try {
      const availableLockers = lockers.filter(locker => !locker.is_occupied && locker.size === size);
      if (availableLockers.length === 0) {
        setAlertMessage('Todos os armários estão ocupados');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      
      // Open modal to capture employee ID
      setLockerSize(size);
      setOpen(true);
    } catch (error) {
      console.error('Error assigning locker:', error);
      setAlertMessage('Erro ao atribuir armário. Tente novamente!');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const releaseLocker = async (lockerId) => {
    try {
      // Send request to server to release the locker
      await axios.put(`https://serverlocker.winglet.app/lockers/${lockerId}`, {
        user_id: "",
        is_occupied: "0"
      }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'application/json'
        }
      });

      // Re-fetch lockers to update the state
      const updatedLockers = await axios.get('https://serverlocker.winglet.app/lockers', {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      if (Array.isArray(updatedLockers.data)) {
        setLockers(updatedLockers.data);
      } else {
        console.error("Fetched data is not an array:", updatedLockers.data);
      }

      setAlertMessage(`Armário ${lockerId} agora está disponível`);
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      console.error('Error releasing locker:', error);
      setAlertMessage('Ops! Não foi possível liberar o armário. Tente novamente!');
      setAlertSeverity('warning');
      setAlertOpen(true);
    }
  };

  const captureAndRecognize = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const img = new Image();
    img.src = imageSrc;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Ensure the image is large enough
      if (img.width < 50 || img.height < 50) {
        console.error('Image too small for processing.');
        return;
      }

      // Convert to grayscale
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = avg; // red
        data[i + 1] = avg; // green
        data[i + 2] = avg; // blue
      }
      ctx.putImageData(imageData, 0, 0);

      // Increase contrast
      ctx.filter = 'contrast(200%)';
      ctx.drawImage(canvas, 0, 0);

      // Perform OCR with error handling
      try {
        const { data: { text } } = await Tesseract.recognize(
          canvas.toDataURL(),
          'eng', // Use 'eng' for better alphanumeric recognition
          {
            logger: m => console.log(m)
          }
        );
        
        const match = text.match(/ORB\s?\d+/); // Matches "ORB" followed by numbers
        if (match) {
          setEmployeeId(match[0]); // Capture the matched string
          clearInterval(intervalRef.current); // Stop capturing once a valid ID is found
          setShowCamera(false); // Hide the camera
        }
      } catch (error) {
        console.error('OCR failed:', error);
      }
    };
  };

  const handleAssign = async () => {
    try {
      const availableLockers = lockers.filter(locker => !locker.is_occupied && locker.size === lockerSize);
      if (availableLockers.length === 0) {
        setAlertMessage('Nenhum armário disponível');
        setAlertSeverity('error');
        setAlertOpen(true);        
        return;
      }
      const randomLocker = availableLockers[Math.floor(Math.random() * availableLockers.length)];

      // Send request to server to assign the locker
      await axios.put(`https://serverlocker.winglet.app/lockers/${randomLocker.id}`, {
        user_id: employeeId,
        is_occupied: "1"
      }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'application/json'
        }
      });

      // Re-fetch lockers to update the state
      const updatedLockers = await axios.get('https://serverlocker.winglet.app/lockers', {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      if (Array.isArray(updatedLockers.data)) {
        setLockers(updatedLockers.data);
      } else {
        console.error("Fetched data is not an array:", updatedLockers.data);
      }

      setAlertMessage(`Armário ${randomLocker.number} atribuído ao colete ${employeeId}`);
      setAlertSeverity('success');
      setAlertOpen(true);
      setOpen(false);
      setEmployeeId('');
    } catch (error) {
      console.error('Error assigning locker:', error);
      setAlertMessage('Ops! Não foi possível atribuir o armário. Tente novamente!');
      setAlertSeverity('warning');
      setAlertOpen(true);
    }
  };

  const filteredLockers = lockers.filter(locker => {
    if (badgeFilter === 'smallAvailable') return !locker.is_occupied && locker.size === 's';
    if (badgeFilter === 'smallOccupied') return locker.is_occupied && locker.size === 's';
    if (badgeFilter === 'bigAvailable') return !locker.is_occupied && locker.size === 'b';
    if (badgeFilter === 'bigOccupied') return locker.is_occupied && locker.size === 'b';
    if (searchTerm) return locker.user_id && locker.user_id.includes(searchTerm);
    return true;
  });

  const handleBadgeClick = (filter) => {
    setBadgeFilter(prevFilter => prevFilter === filter ? '' : filter);
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static" sx={{ bgcolor: "#11111f" }}>
        <Toolbar>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '20px' }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <Button color="inherit" onClick={handleLogout} startIcon={<LogoutIcon />}>
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ padding: '20px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '0 1 auto', display: 'flex', justifyContent: 'space-between' }}>
          <Badge badgeContent={smallAvailable} color="success" onClick={() => handleBadgeClick('smallAvailable')}>
            <img src={smallAvailableImage} alt="Small Lockers Available" style={{ width: '90%', cursor:'pointer'}} />
          </Badge>
          <Badge badgeContent={smallOccupied} color="error" onClick={() => handleBadgeClick('smallOccupied')}>
            <img src={smallOccupiedImage} alt="Small Lockers Occupied" style={{ width: '90%', cursor:'pointer' }} />
          </Badge>
          <Badge badgeContent={bigAvailable} color='success' onClick={() => handleBadgeClick('bigAvailable')}>
            <img src={bigAvailableImage} alt="Big Lockers Available" style={{ width: '90%', cursor:'pointer' }} />
          </Badge>
          <Badge badgeContent={bigOccupied} color="error" onClick={() => handleBadgeClick('bigOccupied')}>
            <img src={bigOccupiedImage} alt="Big Lockers Occupied" style={{ width: '90%', cursor:'pointer' }} />
          </Badge>
        </div>
        <div style={{ marginBottom: '5px', marginTop:'10px', flex: '0 1 auto', textAlign: 'center', color: '#284268' }}>
          <Typography variant='a'> Ocupados: {totalAvailable}% | </Typography>
          <Typography variant='a'> Livres: {totalOccupied}% </Typography>
          <Divider style={{margin:'05px'}}/>
          <Typography variant='a'> Total de Armários: {totalLockers} </Typography>
        </div>
        <TextField
          label="Buscar Colete"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          style={{ marginBottom: '20px', marginTop: '20px', flex: '0 1 auto' }}
        />
        <Divider />
        <Box sx={{height:'100hv', overflowY: 'auto', display:'flex', flexDirection:'column'}}>
          <div style={{ height:'35rem', overflow:'inherit'  }}>
            <List>
              {Array.isArray(filteredLockers) && filteredLockers.map(locker => (
                <ListItem key={locker.id} sx={{ height: '50px', backgroundColor: locker.is_occupied ? '#F8333C' : '#44AF69', color: '#FFF', marginBottom: 1 }}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => locker.is_occupied ? releaseLocker(locker.id) && setSearchTerm('') : assignRandomLocker(locker.size)}>
                      {locker.is_occupied ? <PersonRemoveIcon sx={{color: '#FFF'}}/> : <PersonAddAlt1Icon sx={{color: '#FFF'}}/>}
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'transparent' }}>
                      {lockerSize === 's' ? <PhoneIphoneIcon sx={{color: '#FFF'}}/> : <LuggageIcon sx={{color: '#FFF'}}/>}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={locker.is_occupied ? `${locker.user_id}` : `Armário ${locker.number}`}
                    secondary={locker.is_occupied ? `Armário ${locker.number}` : 'Disponível'}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Box>
        <Typography variant="a" style={{ flexGrow: 1, fontSize:'10px', textAlign:'center'}}>
          V0.1 "Beta" - by winglet - Powered by WFS|Orbital
        </Typography>
      </div>
      <SpeedDial
        ariaLabel="Reservar um armário"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<AddIcon />}
        FabProps={{
          sx: {
            bgcolor: '#284268',
            '&:hover': {
              bgcolor: '#1A2E',
            }
          }
        }}
      >
        <SpeedDialAction
          icon={<CloseFullscreenIcon />}
          tooltipTitle="Pequeno"
          tooltipOpen={true}
          onClick={() => assignRandomLocker('s')}
        />
        <SpeedDialAction
          icon={<OpenInFullIcon />}
          name="big-locker"
          tooltipTitle="Grande"
          tooltipOpen={true}
          onClick={() => assignRandomLocker('b')}
        />
      </SpeedDial>
      <Modal
        open={open}
        onClose={() => setOpen(false) && setEmployeeId('')}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            {lockerSize === 's' ? 'Armário Pequeno' : 'Armário Grande'}
          </Typography>
          {showCamera ? (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="90%"
              height="90%"
              videoConstraints={{
                width: 1280,
                height: 720,
                facingMode: "environment"
              }}
            />
          ) : (
            <Button variant="contained" color="primary" onClick={() => setShowCamera(true)}>
              Reabrir a Câmera
            </Button>
          )}
          <TextField
            label="Colete do Funcionário"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            fullWidth
            style={{ marginTop: 10 }}
          />
          <Button variant="contained" color={employeeId === '' ? 'error' : 'success'} onClick={handleAssign} style={{ marginTop: 10 }} disabled={employeeId === '' ? true : false}>
            Atribuir Armário
          </Button>
        </Box>
      </Modal>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <style>{`
        @media (max-width: 600px) {
          .MuiListItem-root {
            padding: 8px !important;
          }
          .MuiButton-root {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
}