// context/AuthContext.js
import React, { createContext, useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();

const isValidToken = (token) => {
  if (!token) return false;
  const parts = token.split('.');
  return parts.length === 3;
};

export const AuthProvider = ({ children }) => {
  const token = localStorage.getItem('token');
  const [authState, setAuthState] = useState({
    token: token,
    user: isValidToken(token) ? jwtDecode(token) : null,
  });

  const loginUser = async (username, password) => {
    console.log('chamado', username, password);
    try {
      console.log('entrou no try ', username, password);
      const response = await axios.post('https://serverlocker.winglet.app/login', { username, password });
      console.log('response', response);
      localStorage.setItem('token', response.data.token);
      sessionStorage.setItem('token', response.data.token);
      console.log(response);
      setAuthState({
        token: response.data.token,
        user: jwtDecode(response.data.token),
      });
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ authState, loginUser }}>
      {children}
    </AuthContext.Provider>
  );
};