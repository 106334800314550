// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import LockerControlSystemPage from './pages/LockerControlSystemPage';
import LockerControlSystemPageBkp from './pages/LockerControlSystemPageBKP';
import { AuthProvider } from './context/authContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/lockers" element={<LockerControlSystemPage />} />
          <Route path="/lockers2" element={<LockerControlSystemPageBkp />} />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;